<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-form class="multi-col-validation my-5 mx-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.proveedor_id"
            :items="proveedores"
            :label="$t('lbl.proveedor')"
            outlined
            dense
            hide-details
            item-text="name_comercial"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.season_id"
            :items="seasonFree"
            :label="$t('lbl.season')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="temporada_id"
            :disabled="!model.contrate_id || model.hotels.length !== 1"
            @change="setSeason"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.season') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.tipo_destino_id"
            :items="tipoDestino"
            :label="$t('menu.typeDestination')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.typeDestination') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="model.destino"
            :items="itemsDestinos"
            :search-input.sync="searchDestinos"
            :label="$t('lbl.destino')"
            outlined
            dense
            item-text="name"
            item-value="name"
            class="pr-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ noResultDestinos ? $t('booking.noResultSearchFlight') : $t('lbl.destino') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.contrate_id"
            :items="contratesFree"
            item-text="identificador"
            item-value="id"
            :label="$t('lbl.contrato')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="$t('lbl.dateFrom')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                style="border-radius: 5px 0px 0px 5px"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="model.dateIn"
              no-title
              :min="minDate ? minDate.toISOString().substr(0, 10) : ''"
              :max="maxDate ? maxDate.toISOString().substr(0, 10) : ''"
              locale="es"
              :show-current="false"
              @input="
                menuFrom = false
                activeToDate()
              "
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="model.hotels"
            :items="hotelsFree"
            :label="$t('menu.hotels')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            multiple
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.hotels') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.operador_id"
            :items="operadores"
            :label="$t('menu.operador')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <!--<v-autocomplete
            v-model="model.operador_id"
            :items="itemsOperador"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>-->
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :disabled="!model.dateIn"
                :label="$t('lbl.dateTo')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                style="border-radius: 0px 5px 5px 0px"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="model.dateOut"
              no-title
              locale="es"
              :show-current="false"
              :min="
                model.dateIn
                  ? $moment(model.dateIn)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              :max="maxDate ? maxDate.toISOString().substr(0, 10) : ''"
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          v-if="
            (model.proveedor_id && model.proveedor_id !== 0) ||
              (model.season_id && model.season_id !== 0) ||
              (model.tipo_destino_id && model.tipo_destino_id !== 0) ||
              (model.destino && model.destino !== 'Todos') ||
              model.dateIn ||
              model.dateOut ||
              (model.contrate_id && model.contrate_id !== 0) ||
              (model.operador_id && model.operador_id !== 0) ||
              model.hotels.length > 0
          "
          class="mx-2"
          fab
          outlined
          small
          color="error"
          @click="
            $emit('clearSearch')
            searchHotels = false
          "
        >
          <v-icon small>
            {{ icons.mdiEraserVariant }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="text-center"
      >
        <v-btn
          color="primary"
          :loading="loading"
          @click="searchItems"
        >
          <v-icon class="mr-4">
            {{ icons.mdiMagnify }}
          </v-icon>
          {{ $t('btn.search') }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span v-if="itemsTarifarios.length > 0">
          {{ itemsTarifarios.length }} {{ $t('lbl.de') }} {{ hotels.length }} {{ $t('menu.hotels').toLowerCase() }}
        </span>
      </v-col>
    </v-row>
    <v-divider class="mb-4 mt-4"></v-divider>

    <HotelsList
      :user="user"
      :model="model"
      :proveedores="proveedores"
      :operadores="operadores"
      :hotels="hotels"
    />
    <NoResults v-if="itemsTarifarios.length === 0 && searchHotels" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

import HotelsList from './tablas/HotelsList.vue'
import NoResults from '../utils/NoResults.vue'

export default {
  components: {
    HotelsList,
    NoResults,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    proveedores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contratos: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    tipoDestino: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    operadores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotels: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      itemsDestinos: [],
      searchDestinos: null,
      noResultDestinos: false,
      itemsOperador: [],
      searchOperador: null,

      minDate: new Date(),
      maxDate: null,
      menuFrom: false,
      menuTo: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsList: [],
      seasons: [],
      idCayos: null,
      idSolPlaya: null,
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
      searchHotels: state => state.app.searchHotels,
    }),
    computedFromDateFormatted() {
      return this.model.dateIn ? this.$moment(this.model.dateIn).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.model.dateOut ? this.$moment(this.model.dateOut).format('D-MMM-YY') : ''
    },
    contratesFree() {
      let filterContrates = this.contratos
      if (this.model.proveedor_id && this.model.proveedor_id !== 0) {
        const filterResult = filterContrates.filter(e => e.proveedor_id === this.model.proveedor_id)
        filterContrates = filterResult
      }

      if (this.model.operador_id && this.model.operador_id !== 0) {
        const filterResult = filterContrates.filter(e => e.operadorsId.includes(this.model.operador_id))
        filterContrates = filterResult
      }

      return filterContrates
    },
    seasonFree() {
      const arr = []
      arr.push({
        name: 'Todas',
        temporada_id: 0,
      })
      if (this.model.contrate_id && this.model.hotels.length === 1) {
        const cont = this.contratos.filter(e => e.id === this.model.contrate_id)
        if (cont.length > 0) {
          cont[0].seasons.forEach(element => {
            if (element.hotels[0] === this.model.hotels[0]) {
              element.seasons.seasons.forEach(elementSSS => {
                arr.push(elementSSS)
              })
            }
          })
        }
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.model.season_id = 0
      }

      return arr
    },
    hotelsFree() {
      let filterHotels = this.hotels
      if (this.model.operador_id && this.model.operador_id !== 0) {
        const filterResult = filterHotels.filter(e => e.operador_id === this.model.operador_id)
        filterHotels = filterResult
      }
      if (this.model.proveedor_id && this.model.proveedor_id !== 0) {
        const filterResult = filterHotels.filter(e => e.proveedores.includes(this.model.proveedor_id))
        filterHotels = filterResult
      }
      if (this.model.destino && this.model.destino !== 'Todos') {
        const filterResult = filterHotels.filter(
          e => e.country === this.model.destino || e.city === this.model.destino || e.state === this.model.destino,
        )
        filterHotels = filterResult
      }
      if (this.model.contrate_id && this.model.contrate_id !== 0) {
        const cont = this.contratos.filter(e => e.id === this.model.contrate_id)
        if (cont.length > 0) {
          const hp = cont[0].hotels_pactados
          const arr1 = []
          filterHotels.forEach(element => {
            if (hp.includes(element.id)) {
              arr1.push(element)
            }
          })
          filterHotels = arr1
        }
      }
      if (this.model.tipo_destino_id && this.model.tipo_destino_id !== 0) {
        const typeDst = this.tipoDestino.filter(e => e.id === this.model.tipo_destino_id)
        if (typeDst.length > 0) {
          const { slug } = typeDst[0]
          if (slug === 'cayos') {
            const filterResult = filterHotels.filter(
              e => e.tipo_destino_id === this.idCayos || e.tipo_destino_id === this.idSolPlaya,
            )
            filterHotels = filterResult
          } else {
            const filterResult = filterHotels.filter(e => e.tipo_destino_id === this.model.tipo_destino_id)
            filterHotels = filterResult
          }
        }
      }

      return filterHotels
    },
  },
  watch: {
    searchDestinos(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestinos(val.toLowerCase())
      } else {
        this.noResultDestinos = false
      }
    },
    searchOperador(val) {
      if (val !== null && val.length > 0) {
        this.filterOperador(val.toLowerCase())
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  created() {
    if (this.model.destino) {
      this.itemsDestinos.push({
        id: 0,
        name: 'Todos',
      })
    }
    this.seasons.push({
      name: 'Todas',
      temporada_id: 0,
    })

    this.tipoDestino.forEach(element => {
      if (element.slug === 'cayos') {
        this.idCayos = element.id
      } else if (element.slug === 'sol-y-playa') {
        this.idSolPlaya = element.id
      }
    })
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios', 'updateSearchHotels']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },
    filterDestinos(v) {
      this.itemsDestinos = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        // DESTINOS
        this.axios
          .post(
            'destinos-hotels-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data
            this.itemsDestinos = []
            this.itemsDestinos.push({
              id: 0,
              name: 'Todos',
            })

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestinos.push({
                id: element.id,
                name: element.destino,
              })
            })
          })

        setTimeout(() => {
          if (this.itemsDestinos.length === 0) {
            this.noResultDestinos = true
          }
        }, 500)
      }
    },
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },

    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
        this.model.dateOut = this.$moment(this.model.dateIn)
          .add('days', 1)
          .format('YYYY-MM-DD')
      }, 100)
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },

    setSeason() {
      if (this.model.season_id) {
        const result = this.seasonFree.filter(e => e.temporada_id === this.model.season_id)
        if (result.length > 0) {
          this.minDate = this.$moment(result[0].from)
          this.maxDate = this.$moment(result[0].to)
        }
      }
    },

    searchItems() {
      if (this.model.dateIn && this.model.dateOut) {
        this.loading = true
        this.updateSearchHotels(false)
        this.updateItemsTarifarios([])
        this.axios
          .post('tarifarios/hotels', this.model, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res)
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              this.updateSearchHotels(true)
              this.updateItemsTarifarios(res.data.data.items)
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
