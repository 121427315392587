<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-form class="multi-col-validation my-5 mx-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.proveedor_id"
            :items="proveedores"
            :label="$t('lbl.proveedor')"
            outlined
            dense
            hide-details
            item-text="name_comercial"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.season_id"
            :items="seasons"
            :label="$t('lbl.season')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="temporada_id"
            :disabled="!model.contrate_id"
            @change="setSeason"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.season') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.transmision_id"
            :items="transmisiones"
            item-text="name"
            item-value="id"
            :label="$t('menu.trasnmision')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.combustible_id"
            :items="combustibles"
            item-text="name"
            item-value="id"
            :label="$t('menu.combustible')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.contrate_id"
            :items="contratesFree"
            item-text="identificador"
            item-value="id"
            :label="$t('lbl.contrato')"
            outlined
            dense
            hide-details
            @change="setContrate"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="$t('lbl.dateFrom')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                style="border-radius: 5px 0px 0px 5px"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="model.dateIn"
              no-title
              :min="minDate ? minDate.toISOString().substr(0, 10) : ''"
              :max="maxDate ? maxDate.toISOString().substr(0, 10) : ''"
              locale="es"
              :show-current="false"
              @input="
                menuFrom = false
                activeToDate()
              "
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="model.recogida_id"
            :items="itemsRecogida"
            :search-input.sync="searchRecogida"
            :label="$t('booking.recogida')"
            :placeholder="$t('lbl.destinoUOfic')"
            outlined
            dense
            item-text="nameComodin"
            item-value="nameComodin"
            clearable
            return-object
            class="pr-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ noResultRecogida ? $t('booking.noResultSearchFlight') : $t('booking.recogida') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.operador_id"
            :items="operadores"
            :label="$t('menu.operador')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :disabled="!model.dateIn"
                :label="$t('lbl.dateTo')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                style="border-radius: 0px 5px 5px 0px"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="model.dateOut"
              no-title
              locale="es"
              :show-current="false"
              :min="
                model.dateIn
                  ? $moment(model.dateIn)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              :max="maxDate ? maxDate.toISOString().substr(0, 10) : ''"
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          v-if="
            (model.proveedor_id && model.proveedor_id !== 0) ||
              (model.season_id && model.season_id !== 0) ||
              (model.transmision_id && model.transmision_id !== 0) ||
              (model.combustible_id && model.combustible_id !== 0) ||
              (model.contrate_id && model.contrate_id !== 0) ||
              model.recogida_id !== null ||
              model.dateIn ||
              model.dateOut ||
              (model.operador_id && model.operador_id !== 0)
          "
          class="mx-2"
          fab
          outlined
          small
          color="error"
          @click="
            $emit('clearSearch')
            searchCars = false
          "
        >
          <v-icon small>
            {{ icons.mdiEraserVariant }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="text-center"
      >
        <v-btn
          color="primary"
          :loading="loading"
          @click="searchItems"
        >
          <v-icon class="mr-4">
            {{ icons.mdiMagnify }}
          </v-icon>
          {{ $t('btn.search') }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <span v-if="itemsTarifarios.length > 0">
          {{ itemsTarifarios.length }} {{ $t('lbl.de') }} {{ operadoresAll.length }}
          {{ $t('lbl.operadors').toLowerCase() }}
        </span>
      </v-col>
    </v-row>
    <v-divider class="mb-4 mt-4"></v-divider>

    <template v-if="itemsTarifarios.length > 0">
      <CarsList
        v-if="itemsTarifarios[0].type === 'cars'"
        :user="user"
        :model="model"
        :proveedores="proveedores"
        :operadores="operadores"
      />
    </template>
    <NoResults v-if="itemsTarifarios.length === 0 && searchCars" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

import CarsList from './tablas/CarsList.vue'
import NoResults from '../utils/NoResults.vue'

export default {
  components: {
    CarsList,
    NoResults,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    proveedores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    transmisiones: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    combustibles: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contratos: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    operadores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,
      operadoresAll: [],

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      itemsDestinos: [],
      searchDestinos: null,
      noResultDestinos: false,
      itemsOperador: [],
      searchOperador: null,

      minDate: new Date(),
      maxDate: null,
      menuFrom: false,
      menuTo: false,
      permisos: localStorage.getItem('permisos_auth'),
      itemsList: [],
      itemsRecogida: [],
      noResultRecogida: false,
      searchRecogida: null,
      seasons: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
      searchCars: state => state.app.searchCars,
    }),
    computedFromDateFormatted() {
      return this.model.dateIn ? this.$moment(this.model.dateIn).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.model.dateOut ? this.$moment(this.model.dateOut).format('D-MMM-YY') : ''
    },

    contratesFree() {
      let filterContrates = this.contratos
      if (this.model.proveedor_id && this.model.proveedor_id !== 0) {
        const filterResult = filterContrates.filter(e => e.proveedor_id === this.model.proveedor_id)
        filterContrates = filterResult
      }

      if (this.model.operador_id && this.model.operador_id !== 0) {
        const filterResult = filterContrates.filter(e => e.rentadora_id === this.model.operador_id)
        filterContrates = filterResult
      }

      return filterContrates
    },
  },
  watch: {
    searchRecogida(val) {
      if (val) {
        if (val.length > 1) {
          // eslint-disable-next-line no-unused-expressions
          val && this.filterRecogida(val.toLowerCase())
        } else {
          this.noResultRecogida = false
        }
      }
    },
    searchOperador(val) {
      if (val !== null && val.length > 0) {
        this.filterOperador(val.toLowerCase())
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  created() {
    if (this.model.destino) {
      this.itemsDestinos.push({
        id: 0,
        name: 'Todos',
      })
    }
    this.seasons.push({
      name: 'Todas',
      temporada_id: 0,
    })

    this.operadores.forEach(element => {
      if (element.id > 0) {
        this.operadoresAll.push(element)
      }
    })
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios', 'updateSearchCars']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },
    filterRecogida(v) {
      this.itemsRecogida = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        // DESTINOS
        this.axios
          .post(
            'destinos-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data
            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsRecogida.push({
                id: element.id,
                name: element.destino,
                nameComodin: element.destino,
                type: 'destino',
                icon: 'mdi-map-marker',
              })
            })
          })
          .finally(() => {
            setTimeout(() => {
              if (this.itemsRecogida.length === 0) {
                this.noResultRecogida = true
              }
            }, 500)

            // OFICINAS
            this.axios
              .post(
                'oficina-renta-filter-contrate',
                { search: v },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                  },
                },
              )
              .then(res => {
                const result = res.data.data.data

                result.forEach(element => {
                  let icon = 'mdi-office-building-marker-outline'
                  if (element.pto_recogida !== null) {
                    if (element.punto_recogida.icon !== null) {
                      icon = element.punto_recogida.icon
                    }
                  }
                  // eslint-disable-next-line no-nested-ternary
                  this.itemsRecogida.push({
                    id: element.id,
                    name: element.name,
                    nameComodin: element.nameComodin,
                    type: 'oficina',
                    icon,
                  })
                })
              })
              .finally(() => {
                setTimeout(() => {
                  if (this.itemsRecogida.length === 0) {
                    this.noResultRecogida = true
                  }
                }, 500)
              })
          })
      }
    },
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },

    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
        this.model.dateOut = this.$moment(this.model.dateIn)
          .add('days', 1)
          .format('YYYY-MM-DD')
      }, 100)
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },

    setContrate() {
      if (this.model.contrate_id) {
        const result = this.contratos.filter(e => e.id === this.model.contrate_id)
        const { temporadas } = result[0]
        this.seasons = temporadas
      }
    },
    setSeason() {
      if (this.model.season_id) {
        const result = this.seasons.filter(e => e.temporada_id === this.model.season_id)

        const { dates } = result[0]
        this.minDate = this.$moment(dates[0].from)
        this.maxDate = this.$moment(dates[dates.length - 1].to)
      }
    },

    searchItems() {
      if (this.model.dateIn && this.model.dateOut) {
        this.loading = true
        this.$emit('update:search-cars', false)
        this.updateItemsTarifarios([])
        this.axios
          .post('tarifarios/cars', this.model, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res)
            this.updateSearchCars(true)
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              this.updateItemsTarifarios(res.data.data.items)
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
