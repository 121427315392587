<template>
  <v-navigation-drawer
    :value="isSidebarSendActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-send-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('menu.tarifarios') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-send-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.email"
                outlined
                :label="$t('lbl.para')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.subject"
                outlined
                :label="$t('lbl.subject')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <tiptap-vuetify
                v-model="clientCarShop.message"
                :extensions="extensions"
                :placeholder="`${$t('lbl.message')}...`"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-send-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="!clientCarShop.email || !clientCarShop.subject || !clientCarShop.message"
                :loading="loading"
                @click="sendEmail"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
import {
  TiptapVuetify,
  Bold,
  Italic,

  // BulletList,
  // OrderedList,
  // ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  model: {
    prop: 'isSidebarSendActive',
    event: 'update:is-sidebar-send-active',
  },
  props: {
    isSidebarSendActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    nameClient: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      extensions: [
        // BulletList,
        // OrderedList,
        // ListItem,
        Italic,
        Bold,
        HardBreak,
      ],
      icons: {
        mdiClose,
      },
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
    message: {
      get() {
        const msg = `<p>Estimado cliente <b>${
          this.nameClient
        }</b>, a continuación ponemos a su disposición nuestro Tarifario de Servicios para el Producto <b>${
          this.itemsTarifarios[0].type === 'cars' ? 'Renta de Autos' : 'Hoeles'
        }</b>.
          Aquí encontrará los precios y condiciones según las temporadas y cantidades de pax incluidos en el documento.
          <br /><br />
          Cualquier consulta o requerimiento no dude en contactarnos.
          <br /><br />
          Gracias
          <br /><br />
          <span style="margin-left: 10px">Saludos Cordiales</span>
      </p>`

        return msg
      },
    },
  },

  created() {
    this.clientCarShop.subject = 'Tarifario'
    this.clientCarShop.message = this.message
  },
  methods: {
    ...mapMutations(['updateItemsTarifarios', 'updateSearchCars', 'updateSearchHotels', 'updateSearchFlights']),
    sendEmail() {
      const json = {
        client_data: this.clientCarShop,
        items: this.itemsTarifarios,
        action: 'send',
      }
      this.loading = true
      this.axios
        .post('tarifarios/hotels/action', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.msgSendEmail', { n: this.clientCarShop.email }))

            // VACIAR EL ARREGLO
            this.updateSearchCars(false)
            this.updateSearchHotels(false)
            this.updateSearchFlights(false)
            this.updateItemsTarifarios([])
            this.$emit('update:is-sidebar-send-active', false)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
